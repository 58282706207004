import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Scene from './Scene';
import ImagePicker from './ImagePicker';
import Finish from './Finish';
import { baseUrl, assetsUrl } from './config';


const App: React.FC = () => {
  const [screen, setScreen] = useState<'scene' | 'addImage' | 'finish'>(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState<object[]>([]);
  const [sceneNumber, setSceneNumber] = useState<number>(0);
  const [maxScenes, setMaxScenes] = useState<number>(0);
  const [videoId, setVideoId] = useState<string>();
  const [generatedImages, setGeneratedImages] = useState([]);
  const [userImages, setUserImages] = useState([]);

  console.log("selectedImages:", selectedImages)

  useEffect(() => {
    
    const params = new URLSearchParams(window.location.search);
    const id = window.location.pathname.substring(1);
    if (id) setVideoId(id);

    const fetchData = async () => {
      try {
        const url = `${baseUrl}/videos/${videoId}`;
        console.log("videoURL:", url);
        const response = await axios.get(url);
        console.log("app fetch response:", response.data);

        if (response.data.scenes.some(scene => scene.image_id)) {
          const scenes = response.data.scenes.map((e) => {
            if (e['image_type'] === 'generated') {
              return {
                image_type: e['image_type'],
                image_id: e['image_id'],
                image_url: `${assetsUrl}/images/${e.image_id}.png`,
                narration: e['narration'],
              };
            } else if (e['image_type'] === 'user') {
              return {
                image_type: e['image_type'],
                image_id: e['image_id'],
                image_url: `${assetsUrl}/user_images/${e.image_id}.png`,
                narration: e['narration'],
              };
            }
          });
          setSelectedImages(scenes);
          setSelectedImage(scenes[0]);
        }

        const scenes = response.data.scenes;
        setMaxScenes(scenes.length);
      } catch (error) {
        console.error('Error fetching scenes:', error);
      }
    };

    if (videoId) {
      fetchData();
    }
  }, [videoId]);

  useEffect(() => {
    if (maxScenes > 0) {
      setScreen('scene');
    }
  }, [maxScenes]);

  const onNextScene = () => {
    if (selectedImage) {
      const copy = [...selectedImages];
      copy[sceneNumber] = selectedImage;
      setSelectedImages(copy);

      if (copy.length > sceneNumber + 1) {
        setSelectedImage(copy[sceneNumber + 1]);
      } else {
        setSelectedImage(null);
      }

      setSceneNumber((prevSceneNumber) => prevSceneNumber + 1);
    }
  };

  const onSelectedImage = (type: string, data) => {
    const current: object = selectedImages[sceneNumber]

    if (type === 'generated') {
      data['image_url'] = `${assetsUrl}/images/${data['image_id']}.png`
    } else if (type === 'user') {
      data['image_url'] = `${assetsUrl}/user_images/${data['image_id']}.png`
    }

    console.log("selectedImages:", data)

    setSelectedImage({ ...current, 'image_type': type, ...data });
    setScreen('scene');
  };

  const onPreviousScene = () => {
    if (sceneNumber > 0) {
      const index = sceneNumber - 1;
      setSelectedImage(selectedImages[index]);
      setSceneNumber((prevSceneNumber) => prevSceneNumber - 1);
    }
  };

  const onFinished = async () => {
    setScreen('finish');

    const url = `${baseUrl}/videos/${videoId}`
    selectedImages[sceneNumber] = selectedImage;
    
    await axios.post(url, selectedImages, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  const onRemoveImage = () => {
    setSelectedImage(null);
  }

  return (
    <div>
      {screen === 'scene' && 
        <Scene
          onAddImage={() => {
            setScreen('addImage')
          }}
          onPreviousScene={onPreviousScene}
          onNextScene={onNextScene}
          onRemoveImage={onRemoveImage}
          onFinished={onFinished}
          selectedImage={selectedImage}
          sceneNumber={sceneNumber}
          maxScenes={maxScenes}
        />
      }
      {screen === 'addImage' && <ImagePicker onSelection={onSelectedImage} generatedImages={generatedImages} setGeneratedImages={setGeneratedImages} userImages={userImages} setUserImages={setUserImages} />}
      {screen === 'finish' && <Finish />}
    </div>
  );
};

export default App;
