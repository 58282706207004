import React from 'react';

const Finish = () => {

    const onClick = () => {
        window.open("https://wa.me/+14156102615");
        setTimeout(() => {window.close()}, 1000);
    };

    return (
        <div className="flex flex-col h-[calc(100dvh)]">
            <div className="relative flex-1 min-h-0">
                <div className="flex justify-center items-center h-full">
                    <button
                        onClick={onClick}
                        className="bg-blue-500 text-white text-lg p-4"
                    >
                        Regresar a WhatsApp
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Finish;
