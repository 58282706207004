import React from 'react';

const Scene = ({
    onAddImage,
    onPreviousScene,
    onNextScene,
    onRemoveImage,
    onFinished,
    selectedImage,
    sceneNumber,
    maxScenes,
}) => {

    console.log('selectedImage', selectedImage);

    const onFinishedClick = async () => {
        const isConfirmed = window.confirm("¿Todo listo para continuar con la generación del video?");
        if (isConfirmed) {
            onFinished();
        }
    };

    return (
        <div className="flex flex-col h-[calc(100dvh)]">
            <div className="text-center py-4">
                <div className="flex flex-col items-center">
                    <img src="/logo.png" width={100} />
                    <span className="text-lg py-4">Escena {sceneNumber+1} de {maxScenes}</span>
                </div>
            </div>
            <div className="relative flex-1 min-h-0">
                {selectedImage ? (
                    <div 
                        className="absolute inset-0 bg-contain bg-center bg-no-repeat" 
                        style={{ 
                            backgroundImage: `url(${selectedImage.image_url})`,
                            width: '100%',
                            height: 'calc(100% - 64px)',
                        }}
                    >
                        <button
                            onClick={onRemoveImage}
                            className="absolute top-6 right-0 m-4 bg-red-500 text-white p-2"
                            style={{ height: '32px', width: '32px' }}
                        >
                            X
                        </button>
                        <span
                            className="absolute bottom-6 right-0 m-4 bg-blue-100 text-black p-2"
                        >
                            { selectedImage.narration }
                        </span>
                    </div>
                ) : (
                    <div className="flex justify-center items-center h-full">
                        <button
                            onClick={onAddImage}
                            className="bg-blue-500 text-white text-lg p-4 mt-[-100px]"
                        >
                            Agregar imagen
                        </button>
                    </div>
                )}
            </div>
            <div className="flex justify-between">
                <button
                    onClick={onPreviousScene}
                    className="bg-gray-500 text-white text-lg p-4 w-1/2"
                    style={{ height: '64px' }}
                >
                    Anterior
                </button>
                <button
                    disabled={!selectedImage}
                    onClick={sceneNumber === maxScenes - 1 ? onFinishedClick : onNextScene}
                    className={`${selectedImage ? 'bg-green-500' : 'bg-green-300'} text-white text-lg p-4 w-1/2 ${sceneNumber === maxScenes - 1 ? 'bg-yellow-500' : ''}`}
                    style={{ height: '64px' }}
                >
                    {sceneNumber === maxScenes - 1 ? 'Finalizar' : 'Siguiente'}
                </button>
            </div>
        </div>
    );
};

export default Scene;
