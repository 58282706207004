import { useVirtualizer } from '@tanstack/react-virtual';
import React, { useEffect, useState, useRef } from 'react';
import { baseUrl, assetsUrl } from './config';

function ImagePicker({ onSelection, generatedImages, setGeneratedImages, userImages, setUserImages }) {
    const parentRef = useRef();
    const [currentView, setCurrentView] = useState('generated');
    const [imagesToDisplay, setImagesToDisplay] = useState([]);

    console.log('imagesToDisplay', imagesToDisplay);

    const columns = 3;

    useEffect(() => {
        fetchUserImages();
    }, [currentView, userImages]);

    useEffect(() => {
        fetchGeneratedImages();
    }, [currentView]);

    useEffect(() => {
        if (currentView === 'user') {
            setImagesToDisplay(userImages)
        } else {
            setImagesToDisplay(generatedImages)
        }
    }, [currentView, userImages, generatedImages]);

    const fetchUserImages = async () => {
        try {
            if (userImages.length > 0) {
                if (currentView === 'user') {
                    setImagesToDisplay(userImages);
                }
                return;
            }
            const response = await fetch(`${baseUrl}/user/images`);
            if (!response.ok) {
                throw new Error('Failed to fetch images');
            }
            const data = await response.json();
            console.log("fetchUserImages:\n", data)
        
            const urls = data.map((id) => ({
                image_id: id,
                image_url: `${assetsUrl}/user_images/${id}.png`
            }));

            console.log("fetchUserImages urls:\n", urls)
            console.log("currentView:", currentView)

            setUserImages(urls);
            if (currentView === 'user') {
                setImagesToDisplay(urls)
            }
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    const fetchGeneratedImages = async () => {
        try {
            if (generatedImages.length > 0) {
                if (currentView === 'generated') {
                    setImagesToDisplay(generatedImages)
                }
                return;
            }
            const response = await fetch(`${baseUrl}/images`);
            if (!response.ok) {
                throw new Error('Failed to fetch images');
            }
            const data = await response.json();
            console.log("fetchGeneratedImages:\n", data)

            const urls = data.map((id) => ({
                image_id: id,
                image_url: `${assetsUrl}/images/resized_${id}.png`
            }));
            console.log("fetchGeneratedImages urls:\n", urls)
            console.log("currentView:", currentView)

            setGeneratedImages(urls);
            if (currentView === 'generated') {
                setImagesToDisplay(urls)
            }
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    const rowVirtualizer = useVirtualizer({
        count: Math.ceil(imagesToDisplay.length / columns),
        getScrollElement: () => parentRef.current,
        estimateSize: () => window.innerWidth / 3,
    });

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }

        if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
            alert('Formato incorrecto.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(`${baseUrl}/upload/image`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Hubo un problema al subir la imagen. Por favor, intenta de nuevo en unos minutos.');
            }

            setUserImages([])
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const renderVirtualItem = (virtualItem) => {
        const startIndex = virtualItem.index * columns;
        return (
            <div
                key={virtualItem.key}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${window.innerWidth / 3}px`,
                    transform: `translateY(${virtualItem.start}px)`,
                    display: 'flex',
                    justifyContent: 'space-around',
                }}
            >
                {Array.from({ length: columns }).map((_, columnIndex) => {
                    const imageIndex = startIndex + columnIndex;
                    if (imageIndex < imagesToDisplay.length) {
                        return (
                            <img
                                key={imagesToDisplay[imageIndex].image_id}
                                onClick={() => onSelection(currentView, imagesToDisplay[imageIndex])}
                                src={imagesToDisplay[imageIndex].image_url}
                                style={{
                                    width: `calc(100% / ${columns} - 10px)`,
                                    height: `${window.innerWidth / 3}px`,
                                    objectFit: 'cover',
                                    paddingBottom: '10px',
                                }}
                            />
                        );
                    }
                    return null;
                })}
            </div>
        );
    };

    return (
        <div ref={parentRef} className="h-screen overflow-auto h-[calc(100dvh)]">
            <div className="overflow-auto bg-blue-500">
                <label className="block mx-20 my-5">
                    <span className="sr-only">Choose profile photo</span>
                    <input
                        type="file"
                        className="block w-full text-sm text-gray-900
                        file:mr-4 file:py-2 file:px-4
                        file:rounded-full file:border-0
                        file:text-sm file:font-semibold
                        "
                        onChange={handleFileChange}
                    />
                </label>
                <div className="flex justify-around py-5">
                    <button
                        className="bg-white hover:bg-white-700 text-black font-bold py-2 px-4 rounded"
                        onClick={() => {
                            setCurrentView('user')
                            setImagesToDisplay([])
                            setTimeout(()=> {setImagesToDisplay(userImages)}, 100)
                        }}
                    >
                        Mis imágenes
                    </button>
                    <button
                        className="bg-white hover:bg-white-700 text-black font-bold py-2 px-4 rounded"
                        onClick={() => {
                            setCurrentView('generated')
                            setImagesToDisplay([])
                            setTimeout(()=> {setImagesToDisplay(generatedImages)}, 100)
                        }}
                    >
                        Imágenes generadas
                    </button>
                </div>
            </div>
            <div
                style={{
                    height: `${rowVirtualizer.getTotalSize()}px`,
                    width: '100%',
                    position: 'relative',
                    marginTop: '10px',
                }}
            >
                {rowVirtualizer.getVirtualItems().map(renderVirtualItem)}
            </div>
        </div>
    );
}

export default ImagePicker;
